// @flow
import _ from 'lodash';

import type {
  GetChallengeOverview_me_account_class_students as GqlLessonUser,
  GetChallengeOverview_me_account_class_lesson_ClassChallengeLesson_latestAttempts as GqlLessonAttempt,
  GetChallengeOverview_me_account_class_lesson_ClassChallengeLesson_latestAttempts_itemAttempts as GqlAttempt,
  GetChallengeOverview_me_account_class_lesson_ClassChallengeLesson_metrics_assessment_students as GqlLessonMetrics,
} from 'src/graphql/types/generated/GetChallengeOverview';
import type {
  GetRevisionOverview_me_account_class_students as GqlRevisionUser,
  GetRevisionOverview_me_account_class_revision_ClassRevision_latestAttempts as GqlRevisionAttempt,
  GetRevisionOverview_me_account_class_revision_ClassRevision_metrics_assessment_students as GqlRevisionMetrics,
} from 'src/graphql/types/generated/GetRevisionOverview';
import type { OverviewStudent } from 'src/domains/ViewingQuizResults/types';

import { getStudentCohorts } from '../studentCohorts';

export function prepareStudents(
  users: $ReadOnlyArray<GqlLessonUser> | $ReadOnlyArray<GqlRevisionUser>,
  contentMetrics: $ReadOnlyArray<GqlLessonMetrics> | $ReadOnlyArray<GqlRevisionMetrics>
): $ReadOnlyArray<OverviewStudent> {
  const students = [];

  for (const user of users) {
    if (user.__typename === 'ClassStudent') {
      let classification = [];
      for (const metric of contentMetrics) {
        if (metric.studentId === user.id) {
          if (metric.__typename === 'ClassStudentLessonAssessmentMetrics') {
            classification = getStudentCohorts(metric.mark, metric.strength);
          } else {
            classification = getStudentCohorts(metric.mark);
          }
        }
      }
      students.push({
        id: user.id,
        avatar: user.avatar,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email,
        accountStatus: user.accountStatus,
        color: user.color,
        classification,
      });
    }
  }
  return students;
}

export function prepareAttempts(attempts: $ReadOnlyArray<?GqlLessonAttempt> | $ReadOnlyArray<?GqlRevisionAttempt>): {
  [string]: Array<GqlAttempt>,
} {
  return _.groupBy(
    attempts
      .map((attempt) => attempt?.itemAttempts ?? [])
      // $FlowIgnore flow is too out of date to know about array.flat()
      .flat(),
    (attempt) => attempt.contentItemId
  );
}
