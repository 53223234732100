// @flow
import {
  Box,
  Button,
  Container,
  EmptyState,
  Scrollable,
  Illustration,
  Table,
  useTableSelection,
  useMediaQuery,
  HideVisually,
} from '@getatomi/neon';
import { useMemo } from 'react';
import { browserHistory } from 'react-router';

import { getChallengeLabel } from 'src/utils/challenge';
import ActionBar from 'src/components/ActionBar/ActionBar';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import type { NewAttachedContent } from 'src/domains/Tasks/types';

import { cohorts } from '../utilities/studentCohorts';
import prepareMarksColumns from '../utilities/prepareMarksColumns/prepareMarksColumns';
import useFilteredStudents from './hooks/useFilteredStudents';
import type { FilterBy } from './hooks/useFilteredStudents';
import Header from '../Header/Header';
import Tabs from '../Tabs/Tabs';
import type { MarksStudent } from '../types';
import type { LessonMetrics } from './StudentStrength/StudentStrength';
import MarksLayout from './MarksLayout';

export type MarksProps = {|
  attachedContent: NewAttachedContent,
  averageMarkMetrics: LessonMetrics,
  classId: string,
  isRevision?: boolean,
  marksUrl: string,
  overviewUrl: string,
  sequence: $ReadOnlyArray<string>,
  students: $ReadOnlyArray<MarksStudent>,
  subscriptionId: string,
|};

export default function Marks(props: MarksProps) {
  const {
    classId,
    isRevision = false,
    averageMarkMetrics = {
      regionAverageMark: 0,
      schoolAverageMark: 0,
      classAverageMark: 0,
    },
    marksUrl,
    overviewUrl,
    attachedContent,
    sequence,
    students = [],
    subscriptionId,
  } = props;

  const Filtering = useFilteredStudents(students, isRevision ? [cohorts.NEEDS_REVISION] : []);
  const tableRows = Filtering.students;

  const { resetSelection, selectedIds, tableProps } = useTableSelection({
    data: tableRows,
  });

  function appendFilterToUrl(filterBy) {
    const location = browserHistory.getCurrentLocation();
    location.query = { filter: filterBy };
    browserHistory.replace(location);
  }

  function handleFilterChange(filterBy: FilterBy) {
    Filtering.setFilterBy(filterBy);
    appendFilterToUrl(filterBy);
  }

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId,
    classId,
    onSubmit: resetSelection,
  });

  const isMobile = useMediaQuery({ maxWidth: 'breakpointSmall' });

  const columns = useMemo(() => {
    return prepareMarksColumns({
      sequence,
      averageMarkMetrics,
      shouldTruncateFirstName: isMobile,
      shouldHideStrength: isRevision,
    });
  }, [sequence, averageMarkMetrics, isMobile, isRevision]);

  const isEmptyStateVisible = Filtering.students.length === 0;

  return (
    <MarksLayout
      header={<Header heading={`How’s everyone going with this ${getChallengeLabel(attachedContent?.tier)}?`} />}
      tabs={<Tabs overviewUrl={overviewUrl} marksUrl={marksUrl} />}
      filter={
        <Dropdown
          variant="filter"
          ariaLabel="Filter by"
          items={Filtering.options}
          selectedKey={Filtering.filterBy}
          onSelectionChange={handleFilterChange}
        >
          {(item) => <Item>{item.label}</Item>}
        </Dropdown>
      }
      body={
        isEmptyStateVisible ? (
          <Container>
            <EmptyState
              media={<Illustration name="emptystate-add-users" />}
              description="There aren’t any students in this group"
              primaryAction={
                <Button variant="text" onClick={() => handleFilterChange('all')}>
                  Clear filter
                </Button>
              }
            />
          </Container>
        ) : (
          <>
            <Box
              position="relative"
              overflow="hidden"
              flexBasis="60px"
              flexShrink="0"
              flexGrow={isEmptyStateVisible ? '0' : '1'}
            >
              <Scrollable direction="horizontal">
                <Table
                  {...tableProps}
                  columns={columns}
                  data={tableRows}
                  isStickyHeadings
                  testHook="marks-table"
                  isSortable
                  toggleSortBy={Filtering.setSortBy}
                >
                  <caption>
                    <HideVisually>Progress report</HideVisually>
                  </caption>
                  <colgroup>
                    <col style={{ width: '50px' }} />
                    {columns.map((column) => (
                      <col key={column.accessorKey} />
                    ))}
                  </colgroup>
                </Table>
              </Scrollable>
              <ActionBar selectedItemCount={selectedIds.length} onClearSelection={resetSelection}>
                <Button
                  onClick={() =>
                    openTaskDialog({ contents: attachedContent ? [attachedContent] : undefined, students: selectedIds })
                  }
                >
                  Create task
                </Button>
              </ActionBar>
            </Box>
            {taskDialog}
          </>
        )
      }
    />
  );
}
