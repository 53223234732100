// @flow
import invariant from 'invariant';
import { Container, Skeleton } from '@getatomi/neon';
import Helmet from 'react-helmet';

import { getChallengeMarksUrl, getChallengeProgressReportUrl, getClassModuleUrl, getPostUrl } from 'src/utils/routes';
import ClassBreadcrumbs, { BreadcrumbsItem } from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';

import Marks from './Marks/Marks';
import MarksLoader from './Marks/MarksLoader';
import { useGetChallengeMarks } from './useGetChallengeMarks';

export type Params = {|
  classId: string,
  moduleId: string,
  postId: string,
  subscriptionId: string,
|};

export type ChallengeMarksContainerProps = {|
  params: Params,
|};

export function ChallengeMarksContainer(props: ChallengeMarksContainerProps) {
  const { params } = props;
  const { classId, postId: lessonId, moduleId, subscriptionId: accountId } = params;

  const lessonUrl = getPostUrl(accountId, classId, moduleId, lessonId);
  const moduleUrl = getClassModuleUrl(accountId, classId, moduleId);
  const marksUrl = getChallengeMarksUrl(accountId, classId, moduleId, lessonId);
  const overviewUrl = getChallengeProgressReportUrl(accountId, classId, moduleId, lessonId);

  const { data, loading } = useGetChallengeMarks({
    accountId,
    classId,
    lessonId,
    moduleId,
  });

  if (loading && !data) {
    return (
      <>
        <Container marginBottom="spacingLarge3X">
          <Skeleton height={28} width={300} />
        </Container>
        <MarksLoader
          heading="How’s everyone going with this quiz?"
          isRevision={false}
          marksUrl={marksUrl}
          overviewUrl={overviewUrl}
        />
      </>
    );
  }

  invariant(data, 'VQR marks data should be defined');
  const { classData, lesson, sequence, students } = data;

  return (
    <>
      <Helmet>
        <title>
          {lesson.name} | {classData.subject.name}
        </title>
      </Helmet>
      <Container marginBottom="spacingLarge3X">
        <ClassBreadcrumbs
          currentClass={classData}
          // Retail plans don't access VQR
          isRetailPlan={false}
          subject={classData.subject}
          subscriptionId={accountId}
        >
          <BreadcrumbsItem href={moduleUrl}>{lesson.moduleName}</BreadcrumbsItem>
          <BreadcrumbsItem href={lessonUrl}>{lesson.name}</BreadcrumbsItem>
        </ClassBreadcrumbs>
      </Container>
      <Marks
        attachedContent={lesson}
        averageMarkMetrics={lesson.averageMarkMetrics}
        classId={classData.id}
        marksUrl={marksUrl}
        overviewUrl={overviewUrl}
        sequence={sequence}
        students={students}
        subscriptionId={accountId}
      />
    </>
  );
}

export default ChallengeMarksContainer;
