// @flow
import { browserHistory } from 'react-router';
import _ from 'lodash';

import type { ReduxStore, UserRoles, PlanCode, SubscriptionStatusType } from 'src/types';
import Logger from 'src/utils/Logger';
import { trackingEvents } from 'src/constants/tracking';
import { getUser } from 'src/reducers/auth';
import { getActiveSubscriptionId, getUserRole } from 'src/reducers/subscriptions';
import type { Lesson, Subject } from 'src/domains/Post/useGetLesson';

const log = new Logger('utils/tracking');
const analyticsMock = {
  identify: _.noop,
  page: _.noop,
  reset: _.noop,
  track: _.noop,
  user: () => ({
    anonymousId: () => 'segment_disabled_mocked_anonymous_id',
    id: () => 'segment_disabled_mocked_id',
  }),
};

if (__CLIENT__ && !window.analytics) {
  log.warn('It looks like Segment is disabled. Check the configuration if you think it should be enabled.');
  window.analytics = analyticsMock;
}

// User details are retrieved from redux state
const userDetails = {};
let userId;
const setUserDetails = (store: ReduxStore) => {
  const state = store.getState();
  const user = getUser(state);
  userDetails.subscriptionId = getActiveSubscriptionId(state);
  userDetails.graduationYear = user.graduation_year;
  userDetails.signedId = user.signed_id;
  userDetails.email = user.email;
  userDetails.activeRole = getUserRole(state);
  userId = user.id;
};
// Subscribe to redux store change and save the user details
export function subscribeToStoreChange(store: ReduxStore) {
  setUserDetails(store);
  store.subscribe(() => setUserDetails(store));
}

export function getPageDetails() {
  return {
    pageTitle: document.title,
    pageUrl: window.location.href,
    pagePathUrl: window.location.pathname,
  };
}

function getLocalTimezoneInfo() {
  return {
    localTimezone: window.Intl ? window.Intl.DateTimeFormat().resolvedOptions().timeZone : null,
    localTimezoneOffset: new Date().getTimezoneOffset(),
  };
}

export function getLessonTrackingData(lesson: Lesson, subject: Subject, extra?: {}) {
  return {
    asset_id: lesson.videoId, // for video post
    duration: lesson.duration,
    id: lesson.id,
    name: lesson.name,
    parent_id: lesson.categories[lesson.categories.length - 1].id,
    subject_code: subject.code,
    subject_group_code: subject.groupCode,
    ...extra,
  };
}

export function getPageTrackingData(path: string) {
  const subscriptionId = path.match('/subscriptions/([0-9]+)');
  const classId = path.match('/classes/([0-9]+)');
  const postId = path.match('/posts/([0-9]+)');

  return {
    subscriptionId: subscriptionId ? subscriptionId[1] : undefined,
    classId: classId ? classId[1] : undefined,
    postId: postId ? postId[1] : undefined,
  };
}

export function trackPageView() {
  const pageTrackingData = getPageTrackingData(document.location.pathname);
  if (analytics) {
    analytics.page(pageTrackingData);
  }
}

/**
 * Track page view in Segment (not done automatically since our app is a Single Page Application)
 */
export function subscribeToRouteChange() {
  browserHistory.listen(() => {
    trackPageView();
  });
}

type TrackedUser = {
  activePlanCode: PlanCode,
  activePlanStatus: SubscriptionStatusType,
  activeRole: UserRoles,
  activeSubscriptionId: number,
  email: string,
  id: number,
  intercom_hashed_id: string,
  is_free: boolean,
};
/**
 * Send logged in user details to Segment
 * NB: the app is only sending the user id, it assumes that the user details will be sent by the back-end
 */
export function identifyLoggedInUser(user: TrackedUser) {
  if (__CLIENT__) {
    // Segment stores the amplitude session id in localStorage
    const sessionId = localStorage.getItem('analytics_session_id');
    const previousSessionId = localStorage.getItem('previous_analytics_session_id');
    const isNewSession = !previousSessionId || sessionId !== previousSessionId;

    if (isNewSession) {
      const { id, intercom_hashed_id: intercomHashedId, ...traits } = user;
      analytics.identify(id, traits, {
        Intercom: {
          user_hash: intercomHashedId,
        },
      });

      sessionId && localStorage.setItem('previous_analytics_session_id', sessionId);
    }
  }
}

export type TrackEventFn = (name: string, data?: Object, options?: { verifyUser?: boolean }) => void;
export const trackEvent: TrackEventFn = (name, data, options = {}) => {
  const { verifyUser } = options;
  if (__CLIENT__) {
    // $FlowIgnore method calls in optional chains isn't supported
    const trackingUserId = analytics?.user?.()?.id();
    if (verifyUser && (!trackingUserId || +trackingUserId !== userId)) {
      // The Segment user id is missing, the user logged-out in a different browser tab. Or the
      // Segment user id is different from the one in the client state: the user likely logged-in
      // with different users in two separate tabs. Or the user is using a content blocker which
      // prevented the segment id to be set correctly in the identify call.
      return log.warn('Pre-tracking user check failed', { trackingUserId, userId });
    }
    analytics.track(name, { ...data, ...userDetails, ...getPageDetails(), ...getLocalTimezoneInfo() });
  }
};

export function reset() {
  analytics.reset();
}

export function getAnonymousId() {
  // $FlowIgnore
  return analytics?.user?.()?.anonymousId();
}

export function extractLabelFromChildren(children: React.Node) {
  if (typeof children === 'string') {
    return children;
  }

  return React.Children.map(children, (child) => (_.isString(child) ? child : _.get(child, 'props.children')))
    .filter(_.isString)
    .filter(Boolean)
    .join('')
    .trim();
}

export function trackPotentialUser(traitName: string, traitValue: string) {
  if (!traitValue) {
    return;
  }

  trackEvent(trackingEvents.userRegistrationDetailsEntered, { [traitName]: traitValue });
}
