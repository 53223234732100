// @flow
import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { Container, Flex, useTableSelection, useToast } from '@getatomi/neon';

import type { UserGroupType } from 'src/types';
import ButtonAdd from 'src/components/ButtonAdd/ButtonAdd';
import EmptyStateSearch from 'src/components/EmptyStateSearch/EmptyStateSearch';
import SearchInput from 'src/components/SearchInput/SearchInput';
import type { ClassUser } from 'src/domains/Class/useGetClassUsers';

import AddUsersDialog from '../AddUsersDialog/AddUsersDialog';
import RemoveUserFromClassDialog from './RemoveUserFromClassDialog/RemoveUserFromClassDialog';
import EmptyStateClassUsers from './EmptyStateClassUsers/EmptyStateClassUsers';
import UsersTable from './UsersTable/UsersTable';
import useDeleteClassUsers from '../useDeleteClassUsers';
import useResendStudentInvites from './useResendStudentInvites/useResendStudentInvites';

export type UsersTableWithActionsProps = {|
  canManageUsers: boolean,
  classId: string,
  className: string,
  isSetupMode: boolean,
  subscriptionId: string,
  users: $ReadOnlyArray<ClassUser>,
  userType: UserGroupType,
|};

export default function UsersTableWithActions(props: UsersTableWithActionsProps) {
  const { canManageUsers, classId, className, isSetupMode, subscriptionId, users, userType } = props;
  const [isAddModalOpened, setIsAddModalOpened] = useState<boolean>(false);
  const [showRemovePrompt, setShowRemovePrompt] = useState<boolean>(false);
  const [filterTerm, setFilterTerm] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<?$ReadOnlyArray<ClassUser>>(null);
  const { deleteClassUsers, isDeletingClassUsers } = useDeleteClassUsers(userType);

  const toast = useToast();
  const resendStudentInvites = useResendStudentInvites();

  const visibleUsers = filteredUsers || users;
  const isEmpty = visibleUsers.length === 0;
  const hasUsers = users.length > 0;

  const { resetSelection, selectedIds, tableProps } = useTableSelection({ data: visibleUsers });

  const resetFiltering = () => {
    setFilterTerm('');
    setFilteredUsers(null);
  };

  useEffect(() => {
    // reset filtering when the component receives different users (e.g when adding students to a class)
    resetFiltering();
  }, [users]);

  function onSearch(searchTerm: string) {
    const term = searchTerm.trim().toLowerCase();

    // search term is empty, reset the filteredUsers list
    if (term === '') {
      return resetFiltering();
    }

    const newFilteredUsers = sortBy(
      users
        .map((user) => {
          let score = -1;

          // search the fullname first
          if (user.firstName && user.lastName) {
            score = `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(term);
          }

          // search term was not found in the name, try the email
          if (score === -1) {
            score = user.email.toLowerCase().indexOf(term);
          }

          return { ...user, score };
        })
        .filter((user) => user.score >= 0),
      ['score', 'first_name']
    );

    setFilterTerm(searchTerm);
    setFilteredUsers(newFilteredUsers.map(({ score, ...user }) => user));
  }

  const onToggleAddUserModal = () => {
    setIsAddModalOpened((state) => !state);
  };

  const openRemovePrompt = () => {
    setShowRemovePrompt(true);
  };

  const closeRemovePrompt = () => {
    setShowRemovePrompt(false);
  };

  const onBatchRemoveHandler = async () => {
    await deleteClassUsers({ classId, userIds: selectedIds });
    closeRemovePrompt();
    resetSelection();
  };

  const onBatchResendHandler = async (resendUserIds?: $ReadOnlyArray<string>) => {
    try {
      await resendStudentInvites({
        accountId: subscriptionId,
        userIds: resendUserIds ?? [],
      });
      toast.success(
        resendUserIds && resendUserIds.length > 1
          ? 'The invitations were sent successfully.'
          : 'The invitation was sent successfully.'
      );
      resetSelection();
    } catch {
      toast.error('There was an error when sending the invitations. Please try again.');
    }
  };

  return (
    <>
      {hasUsers ? (
        <Container>
          <Flex
            alignItems={{ base: 'center', tablet: 'baseline' }}
            justifyContent="space-between"
            marginBottom={{ base: 'spacingSmall', tablet: 'spacingLarge5X' }}
            gap="spacingLarge3X"
          >
            <SearchInput
              placeholder={`Search ${userType}`}
              onChange={onSearch}
              onReset={resetFiltering}
              value={filterTerm}
            />

            {/** Add user button **/}
            <ButtonAdd
              assistiveText={`Add ${userType}`}
              testHook={`add-${userType}-button`}
              onClick={onToggleAddUserModal}
            >
              Add {userType}
            </ButtonAdd>
          </Flex>
        </Container>
      ) : (
        <EmptyStateClassUsers userType={userType} onAddClick={onToggleAddUserModal} />
      )}

      {/** Users list **/}
      {!isEmpty ? (
        <Container>
          <UsersTable
            classId={classId}
            userType={userType}
            users={visibleUsers}
            onBatchResendInvites={isSetupMode ? null : onBatchResendHandler}
            openRemovePrompt={openRemovePrompt}
            resetSelection={resetSelection}
            selectedIds={selectedIds}
            tableProps={tableProps}
          />
        </Container>
      ) : (
        hasUsers && <EmptyStateSearch contentType={userType} searchTerm={filterTerm} />
      )}

      {/** Add user modal **/}
      <AddUsersDialog
        canManageUsers={canManageUsers}
        classId={classId}
        className={className}
        onClose={onToggleAddUserModal}
        showModal={isAddModalOpened}
        subscriptionId={subscriptionId}
        userType={userType}
      />

      {/** Delete alert dialog used for multiple delete and single delete on mobile **/}
      <RemoveUserFromClassDialog
        userType={userType}
        selectedUsers={selectedIds}
        isDeletingClassUsers={isDeletingClassUsers}
        isOpen={showRemovePrompt}
        onDismiss={closeRemovePrompt}
        onConfirm={onBatchRemoveHandler}
      />
    </>
  );
}
