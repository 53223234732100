// @flow
import { extname } from 'path';

type SignedUpload = {
  +headers: string,
  +url: string,
};

export const uploadFile = async ({ signedUpload, file }: { file: File, signedUpload: SignedUpload }) => {
  const headers = JSON.parse(signedUpload.headers);

  const uploaded = await fetch(signedUpload.url, {
    method: 'put',
    body: file,
    headers,
  });

  if (uploaded.status !== 200) {
    throw new Error('Unable to upload file.');
  }
};
export const getFileExtension = (fileName: string) => extname(fileName).replace(/^\./, '');
export const getSimilarExtension = (extension: string) => (extension === 'jpeg' ? 'jpg' : extension);
export const convertSizeToMB = (fileSizeInBytes: number) => `${(fileSizeInBytes / 1024 ** 2).toFixed(1)}MB`;
