// @flow
import { useEffect } from 'react';
import { type ContextRouter } from 'react-router';
import Helmet from 'react-helmet';
import { Box, Stack, Container, Heading, useToast } from '@getatomi/neon';

import getSubjectColors from 'src/utils/getSubjectColors';
import { getTaskUrl, getClassTasksUrl, getTaskProgressReportUrl } from 'src/utils/routes';
import { trackEvent } from 'src/utils/tracking';
import { trackingCtas, trackingCtaTypes, trackingEvents } from 'src/constants/tracking';
import useAlertDialog from 'src/hooks/components/useAlertDialog';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import useDuplicateTaskDialog from 'src/domains/Tasks/components/useDuplicateTaskDialog/useDuplicateTaskDialog';
import useDeleteTaskAlertDialog from 'src/domains/Tasks/components/useDeleteTaskAlertDialog/useDeleteTaskAlertDialog';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import ClassBreadcrumbs, { BreadcrumbsItem } from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';

import type { ClassTask as ClassTaskData, CurrentClass } from './useGetClassTask';
import useTaskSubscriptions from '../useTaskSubscriptions';
import TaskMarkdown from '../components/TaskMarkdown';
import ClassTaskMenu from './ClassTaskMenu';
import ClassTaskCard from './ClassTaskCard';

export type ClassTaskProps = {|
  currentClass: CurrentClass,
  isFreePlan: boolean,
  region: string,
  router: ContextRouter,
  subscriptionId: string,
  taskData: ClassTaskData,
  userId: string,
|};
function ClassTask(props: ClassTaskProps) {
  const { currentClass, isFreePlan, region, router, subscriptionId, taskData, userId } = props;
  const { id: classId, subject } = currentClass;

  const taskTitle = taskData.title || 'Untitled';

  const toast = useToast();

  const [, setNavigationContext] = useNavigationContext();
  useEffect(() => {
    setNavigationContext({
      location: 'task',
      metadata: {
        accountId: subscriptionId,
        classId,
        taskId: taskData.id,
        title: taskTitle,
      },
    });
  }, [classId, subscriptionId, setNavigationContext, taskData.id, taskTitle]);

  useTaskSubscriptions({
    audience: 'teacher',
    classId,
    userId,
  });

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId,
    classId,
  });

  const onDuplicateTaskSubmit = ({ duplicateTask, duplicateClassId }) => {
    openTaskDialog({ task: duplicateTask, classId: duplicateClassId });
  };

  const [duplicateTaskDialog, { open: openDuplicateTaskModal }] = useDuplicateTaskDialog({
    currentClass: {
      id: classId,
      name: currentClass.name,
      subject: {
        code: subject.code,
        groupCode: subject.groupCode,
        color: subject.color,
        levels: subject.levels,
      },
    },
    onSubmit: onDuplicateTaskSubmit,
  });

  const [deleteTaskAlertDialog, { open: openDeleteTaskAlertDialog }] = useDeleteTaskAlertDialog({
    onConfirm: () => {
      router.push(getClassTasksUrl(subscriptionId, classId));
    },
  });

  const [infectedPrompt, showInfectedPrompt] = useAlertDialog({
    closeOnConfirm: true,
    heading: 'This file did not pass the virus security scan and can’t be downloaded.',
    variant: 'danger',
    onConfirmLabel: 'Ok, thanks',
    isDismissable: false,
  });

  const copyTaskUrlToClipboard = async () => {
    const url = `${window.location.origin}${getTaskUrl(subscriptionId, classId, taskData.id)}`;
    try {
      await navigator.clipboard.writeText(url);
      toast.success('Link copied to the clipboard.');
      trackEvent(trackingEvents.ctaClicked, {
        cta: trackingCtas.copyLink,
        type: trackingCtaTypes.button,
        url,
        taskId: taskData.id,
        subject_code: subject.code,
      });
    } catch (error) {
      toast.error('There was an error copying the link to the clipboard. Please try again.');
    }
  };
  const subjectColorRange = getSubjectColors(subject.groupCode, subject.color);

  const isDraft = taskData.status === 'draft';
  const isScheduled = taskData.status === 'scheduled';
  const isPublished = taskData.status === 'published';

  const classTasksUrl = getClassTasksUrl(subscriptionId, classId, taskData.status);

  return (
    <>
      <Helmet title={taskTitle} />
      <Box marginBottom={{ base: 'spacingRoot', tablet: 'spacingLarge5X' }}>
        <Container>
          <ClassBreadcrumbs
            currentClass={currentClass}
            isRetailPlan={false}
            subject={subject}
            subscriptionId={subscriptionId}
          >
            <BreadcrumbsItem href={classTasksUrl}>Tasks</BreadcrumbsItem>
          </ClassBreadcrumbs>
        </Container>
      </Box>
      <Container maxWidth="sizeContainerRoot">
        <Stack spacing="spacingLarge7X">
          <Stack spacing="spacingLarge2X">
            <ClassTaskCard
              currentClass={currentClass}
              isDraft={isDraft}
              isScheduled={isScheduled}
              subjectColorRange={subjectColorRange}
              taskData={taskData}
            />
            <ClassTaskMenu
              copyTaskUrlToClipboard={copyTaskUrlToClipboard}
              isDraft={isDraft}
              isPublished={isPublished}
              openDeleteTaskAlertDialog={() => openDeleteTaskAlertDialog(taskData.id, taskData.status)}
              openDuplicateTaskDialog={() => openDuplicateTaskModal(taskData)}
              openTaskDialog={() => openTaskDialog({ task: taskData })}
              progressReportUrl={getTaskProgressReportUrl(subscriptionId, classId, taskData.id)}
            />
          </Stack>
          <Stack spacing="spacingLarge3X">
            <Heading as="h1">{taskTitle}</Heading>
            <TaskMarkdown
              attachments={taskData.attachments}
              body={taskData.body}
              classId={classId}
              contents={taskData.contents}
              isDraft={isDraft}
              isFreePlan={isFreePlan}
              region={region}
              showInfectedPrompt={showInfectedPrompt}
              subjectColor={subject.color}
              subscriptionId={subscriptionId}
              updatedAt={taskData.updatedAt}
            />
          </Stack>
        </Stack>
      </Container>
      {infectedPrompt}
      {taskDialog}
      {deleteTaskAlertDialog}
      {duplicateTaskDialog}
    </>
  );
}

export default ClassTask;
