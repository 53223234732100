// @flow
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Flex } from '@getatomi/neon';

import Button, { type Size } from 'src/components/Button/Button';
import { getPostUniversalUrl } from 'src/utils/routes';
import { trackingCtas } from 'src/constants/tracking';
import useEmbedContent from 'src/domains/LTI/SelectLessons/useEmbedContent';

type EmbedButtonPortalProps = {|
  postId: string,
  postName: string,
|};

type PostEmbedButtonProps = {|
  postId: string,
  postName: string,
  size: Size,
|};

function PostEmbedButton(props: PostEmbedButtonProps) {
  const { postId, postName, size } = props;
  const { embedForm, embedContent } = useEmbedContent();

  return (
    <>
      <Button
        onClick={() => {
          embedContent([
            {
              id: postId,
              name: postName,
              embedUrl: getPostUniversalUrl(postId, { isEmbed: 'true' }),
            },
          ]);
        }}
        size={size}
        trackingData={{
          cta: trackingCtas.ltiEmbedSingleLesson,
        }}
      >
        Embed
      </Button>

      {embedForm}
    </>
  );
}

export default function EmbedButtonPortal(props: EmbedButtonPortalProps) {
  /**
   * This component is required to render the `PostEmbedButton`
   * within the `Header` component when viewing lessons via an
   * LTI route.
   */
  const { postId, postName } = props;

  const [embedButtonPortal, setEmbedButtonPortal] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const portalDestination: ?HTMLElement = document.querySelector('#embed-portal-destination');
    if (portalDestination) {
      setEmbedButtonPortal(portalDestination);
    }
  }, []);

  if (!embedButtonPortal) {
    return null;
  }

  return createPortal(
    <Flex alignItems="center" height="sizeFull">
      <PostEmbedButton postId={postId} postName={postName} size="small1X" />
    </Flex>,
    embedButtonPortal
  );
}
