// @flow
import { useMemo } from 'react';
import { ActionBar, Flex, InfoTip, Stack, Table, Text, Truncate, useMediaQuery } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import UserName from 'src/components/UserName/UserName';
import Avatar from 'src/components/Avatar/Avatar';
import Date from 'src/components/Date/Date';
import type { UserGroupType } from 'src/types';
import userAccountStatuses from 'src/constants/userAccountStatuses';
import type { ClassUser } from 'src/domains/Class/useGetClassUsers';

import UserRowActions from './UserRowActions/UserRowActions';

function InvitedUserTooltip({ userType }: { userType: UserGroupType }) {
  const userTypeSingular = userType === 'students' ? 'student' : 'teacher';
  return (
    <InfoTip
      content={`This ${userTypeSingular} needs to accept their email invitation to join your school account. Resend the invite or search “joining your school account” in the Atomi Help Centre.`}
    />
  );
}

// These table props are provided by Neon's useTableSelection hook,
// so we have to re-define these types here for Flow.
type RowSelectionState = {
  [key: string]: boolean,
};

type TableProps = {|
  enableSelection: boolean,
  initialState: {
    rowSelection: RowSelectionState,
  },
  onSelectionChange: (rowSelectionState: RowSelectionState) => void,
|};

export type UsersTableProps = {|
  classId: string,
  onBatchResendInvites: ((selectedIds: Array<string>) => Promise<void>) | null,
  openRemovePrompt: () => void,
  resetSelection: () => void,
  selectedIds: Array<string>,
  tableProps: TableProps,
  users: $ReadOnlyArray<ClassUser>,
  userType: UserGroupType,
|};

type ColumnMeta = {
  isMobile?: boolean,
};

export default function UsersTable(props: UsersTableProps) {
  const { classId, users, userType, onBatchResendInvites, openRemovePrompt, resetSelection, selectedIds, tableProps } =
    props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row: { original: user } }: { row: { original: ClassUser } }) => {
          const isInvitedUser = user.accountStatus !== userAccountStatuses.ACTIVE;
          if (isMobile) {
            return user.firstName && user.lastName ? (
              <Stack spacing="spacingSmall2X">
                <span>
                  <UserName firstName={user.firstName} lastName={user.lastName} />
                  {isInvitedUser && <InvitedUserTooltip userType={userType} />}
                </span>
                <Flex alignItems="center">
                  <Truncate>
                    <Text as="span" fontSize="fontSizeSmall1X" color="colorTextSubtler">
                      {user.email}
                    </Text>
                  </Truncate>
                </Flex>
              </Stack>
            ) : (
              <>
                <Truncate>{user.email}</Truncate>
                {isInvitedUser && <InvitedUserTooltip userType={userType} />}
              </>
            );
          }
          return (
            <Flex alignItems="center">
              <Avatar user={user} withText variant={isInvitedUser ? 'muted' : undefined} />
              {isInvitedUser && <InvitedUserTooltip userType={userType} />}
            </Flex>
          );
        },
        meta: {
          isHeading: true,
          isMobile: true,
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        cell: ({ getValue }) => <Truncate>{getValue()}</Truncate>,
      },
      {
        accessorKey: 'lastLoggedInAt',
        header: 'Last Session',
        cell: ({ getValue }) => {
          const date = getValue();
          if (!date) return <Text aria-hidden testHook="lastSession-empty" />;
          return <Date value={getValue()} format="D MMM YYYY [at] HH:mm" />;
        },
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        cell: ({ row: { original: user } }) => <UserRowActions user={user} userType={userType} classId={classId} />,
        meta: {
          isEndAligned: true,
          isMobile: true,
        },
      },
    ],
    [classId, isMobile, userType]
  );
  const data = useMemo(
    () => users.map((user) => ({ ...user, isInactive: user.accountStatus !== userAccountStatuses.ACTIVE })),
    [users]
  );

  if (!users) return null;

  return (
    <>
      <Table
        {...tableProps}
        columns={isMobile ? columns.filter(({ meta }: { meta?: ColumnMeta }) => meta?.isMobile) : columns}
        data={data}
        isFixedLayout
        testHook="users-table"
      >
        <colgroup>
          <col width="50px" />
          {isMobile ? (
            <>
              <col />
              <col width="65rem" />
            </>
          ) : (
            <>
              <col width="35%" />
              <col width="40%" />
              <col width="20%" />
              <col width="65rem" />
            </>
          )}
        </colgroup>
      </Table>
      <ActionBar selectedItemCount={selectedIds.length} onClearSelection={resetSelection}>
        {onBatchResendInvites && <Button onClick={() => onBatchResendInvites(selectedIds)}>Resend invites</Button>}
        <Button onClick={() => openRemovePrompt()}>Remove</Button>
      </ActionBar>
    </>
  );
}
