// @flow
import _ from 'lodash';
import invariant from 'invariant';

import type {
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_challenge_items as GqlChallengeItem,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_latestAttempts as GqlChallengeAttempt,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_latestAttempts_itemAttempts as GqlChallengeItemAttempt,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_metrics as GqlChallengeMetrics,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_metrics_assessment_students as GqlChallengeAssessmentMetrics,
  GetChallengeMarks_me_account_class_students as GqlChallengeUser,
} from 'src/graphql/types/generated/GetChallengeMarks';
import type {
  GetRevisionMarks_me_account_class_revision_ClassRevision_items as GqlRevisionItem,
  GetRevisionMarks_me_account_class_revision_ClassRevision_latestAttempts as GqlRevisionAttempt,
  GetRevisionMarks_me_account_class_revision_ClassRevision_latestAttempts_itemAttempts as GqlRevisionItemAttempt,
  GetRevisionMarks_me_account_class_revision_ClassRevision_metrics as GqlRevisionMetrics,
  GetRevisionMarks_me_account_class_revision_ClassRevision_metrics_assessment_students as GqlRevisionAssessmentMetrics,
  GetRevisionMarks_me_account_class_students as GqlRevisionUser,
} from 'src/graphql/types/generated/GetRevisionMarks';
import type { UserAccountStatusType } from 'src/types';
import type { MarksStudent } from 'src/domains/ViewingQuizResults/types';
import userFullName from 'src/utils/userFullName';

import type { GetQuestionUrl } from '../getQuestionUrlFactory';
import { getStudentCohorts, type StudentCohort } from '../studentCohorts';
import { getIndividualQuestionResult } from './getIndividualQuestionResult';

export type MarksQuestion = {|
  result: ?string,
  status: ?'success' | 'error',
  type: ?'multiple-choice' | 'self-marked' | 'default',
  uniqueId: ?string,
  url: string,
|};

type PreparedStudent = {|
  accountStatus: UserAccountStatusType,
  avatar: ?string,
  color: number,
  email: string,
  firstName: string,
  fullName: string,
  id: string,
  lastName: string,
|};

type PreparedChallengeAttempt = {|
  duration: ?number,
  hasAttempt: boolean,
  lastAttempt: ?string,
|};

type PreparedAssessmentMetrics = {|
  classification: $ReadOnlyArray<StudentCohort>,
  mark: ?number,
  strength?: ?number,
|};

export type PreparedQuestion = {|
  id: string,
  result: ?string,
  status: ?string,
  type: ?string,
  url: string,
|};

const prepareStudentData = (student: GqlChallengeUser | GqlRevisionUser): PreparedStudent => {
  invariant(student.__typename === 'ClassStudent', 'User should be a class student');
  return {
    accountStatus: student.accountStatus,
    avatar: student.avatar,
    color: student.color,
    email: student.email,
    firstName: student.firstName || '',
    fullName: userFullName(student.firstName, student.lastName) ?? student.email,
    id: student.id,
    lastName: student.lastName || '',
  };
};

const prepareAttemptData = (attempt: ?GqlChallengeAttempt | ?GqlRevisionAttempt): PreparedChallengeAttempt => {
  if (attempt != null) {
    return {
      duration: attempt.durationInSeconds,
      lastAttempt: attempt.attemptedAt,
      hasAttempt: true,
    };
  }
  return {
    duration: null,
    lastAttempt: null,
    hasAttempt: false,
  };
};

const prepareAssessmentMetricsData = (assessmentMetrics: ?GqlChallengeAssessmentMetrics): PreparedAssessmentMetrics => {
  if (!assessmentMetrics) {
    return {
      mark: 0,
      strength: 0,
      classification: [],
    };
  }

  const { mark, strength } = assessmentMetrics;
  const classification = getStudentCohorts(mark, strength);
  return {
    mark,
    strength,
    classification,
  };
};

const prepareRevisionMetricsData = (assessmentMetrics: ?GqlRevisionAssessmentMetrics): PreparedAssessmentMetrics => {
  if (!assessmentMetrics) {
    return {
      mark: 0,
      classification: [],
    };
  }

  const { mark } = assessmentMetrics;
  const classification = getStudentCohorts(mark, null);
  return {
    mark,
    classification,
  };
};

function prepareQuestions({
  getQuestionUrl,
  itemAttempts,
  items,
  userId,
}: {
  getQuestionUrl: GetQuestionUrl,
  itemAttempts: $ReadOnlyArray<?GqlChallengeItemAttempt> | $ReadOnlyArray<?GqlRevisionItemAttempt>,
  items: $ReadOnlyArray<GqlChallengeItem> | $ReadOnlyArray<GqlRevisionItem>,
  userId: string,
}): $ReadOnlyArray<PreparedQuestion> {
  const lookupItemAttempts = _.keyBy(itemAttempts, (attempt) => attempt?.contentItemId);

  return items.map((item) => {
    const { id } = item;
    const itemAttempt = lookupItemAttempts[id];

    const { result, status, type } = getIndividualQuestionResult(itemAttempt, item);

    const url = getQuestionUrl({ itemId: item.id, userId });

    return {
      id,
      result,
      status,
      type,
      url,
    };
  });
}

export function prepareChallengeStudents(
  users: $ReadOnlyArray<GqlChallengeUser>,
  attempts: $ReadOnlyArray<?GqlChallengeAttempt>,
  metrics: GqlChallengeMetrics,
  items: $ReadOnlyArray<GqlChallengeItem>,
  getQuestionUrl: GetQuestionUrl
): $ReadOnlyArray<MarksStudent> {
  const lookupAttempts = _.keyBy(attempts.filter(Boolean), 'userId');
  const lookupAssessmentMetrics = _.keyBy(metrics.assessment.students, 'studentId');
  const lookupProgressMetrics = _.keyBy(metrics.progress.students, 'studentId');

  return users
    .map((student) => {
      if (student.__typename !== 'ClassStudent') {
        return null;
      }

      const challengeAttempt: ?GqlChallengeAttempt = lookupAttempts[student.id];
      const challengeAttemptData = prepareAttemptData(challengeAttempt);

      const assessmentMetrics = lookupAssessmentMetrics[student.id];
      const assessmentMetricsData = prepareAssessmentMetricsData(assessmentMetrics);

      const progressMetrics = lookupProgressMetrics[student.id];
      const completions = progressMetrics?.cumulativeCompletionCount;

      const questionAttempts: $ReadOnlyArray<?GqlChallengeItemAttempt> = challengeAttempt?.itemAttempts ?? [];
      const questionsData = prepareQuestions({
        userId: student.id,
        items,
        itemAttempts: questionAttempts,
        getQuestionUrl,
      });

      const studentData = prepareStudentData(student);

      const hasPartialAttempt = !challengeAttemptData.hasAttempt && Boolean(assessmentMetricsData.mark);

      const reviewUrl = getQuestionUrl({ userId: student.id, itemId: null });

      return {
        ...assessmentMetricsData,
        ...challengeAttemptData,
        ...studentData,
        completions,
        hasPartialAttempt,
        questions: _.keyBy(questionsData, 'id'),
        reviewUrl,
      };
    })
    .filter(Boolean);
}

export function prepareRevisionStudents(
  users: $ReadOnlyArray<GqlRevisionUser>,
  attempts: $ReadOnlyArray<?GqlRevisionAttempt>,
  metrics: GqlRevisionMetrics,
  items: $ReadOnlyArray<GqlRevisionItem>,
  getQuestionUrl: GetQuestionUrl
): $ReadOnlyArray<MarksStudent> {
  const lookupAttempts = _.keyBy(attempts.filter(Boolean), 'userId');
  const lookupAssessmentMetrics = _.keyBy(metrics.assessment.students, 'studentId');
  const lookupProgressMetrics = _.keyBy(metrics.progress.students, 'studentId');

  return users
    .map((student) => {
      if (student.__typename !== 'ClassStudent') {
        return null;
      }

      const revisionAttempt: ?GqlRevisionAttempt = lookupAttempts[student.id];
      const revisionAttemptData = prepareAttemptData(revisionAttempt);

      const assessmentMetrics = lookupAssessmentMetrics[student.id];
      const assessmentMetricsData = prepareRevisionMetricsData(assessmentMetrics);

      const progressMetrics = lookupProgressMetrics[student.id];
      const completions = progressMetrics?.cumulativeCompletionCount;

      const questionAttempts: $ReadOnlyArray<?GqlRevisionItemAttempt> = revisionAttempt?.itemAttempts ?? [];
      const questionsData = prepareQuestions({
        userId: student.id,
        items,
        itemAttempts: questionAttempts,
        getQuestionUrl,
      });

      const studentData = prepareStudentData(student);

      const hasPartialAttempt = !revisionAttemptData.hasAttempt && Boolean(assessmentMetricsData.mark);

      const reviewUrl = getQuestionUrl({ userId: student.id, itemId: null });

      return {
        ...assessmentMetricsData,
        ...revisionAttemptData,
        ...studentData,
        completions,
        hasPartialAttempt,
        questions: _.keyBy(questionsData, 'id'),
        reviewUrl,
      };
    })
    .filter(Boolean);
}
