// @flow
import { Skeleton } from '@getatomi/neon';

import Header from 'src/domains/ViewingQuizResults/Header/Header';
import Tabs from 'src/domains/ViewingQuizResults/Tabs/Tabs';

import OverviewLayout from '../OverviewLayout/OverviewLayout';

type OverviewLoaderProps = {|
  heading: string,
  marksUrl: string,
  overviewUrl: string,
|};

export default function OverviewLoader(props: OverviewLoaderProps) {
  const { heading, marksUrl, overviewUrl } = props;

  return (
    <OverviewLayout
      header={<Header heading={heading} />}
      tabs={<Tabs overviewUrl={overviewUrl} marksUrl={marksUrl} />}
      completionRateMetric={
        <Skeleton testHook="loader-completion-rate-metric" height={{ base: 120, tablet: 420 }} animation="wave" />
      }
      averageMarkMetric={
        <Skeleton testHook="loader-average-mark-metric" height={{ base: 120, tablet: 420 }} animation="wave" />
      }
      studentClassificationMetrics={
        <>
          <Skeleton
            testHook="loader-student-classification-metric-going-well"
            height={{ base: 100, tablet: 202 }}
            animation="wave"
          />
          <Skeleton
            testHook="loader-student-classification-metric-need-help"
            height={{ base: 100, tablet: 202 }}
            animation="wave"
          />
        </>
      }
      challengingQuestionList={
        <Skeleton testHook="loader-challenging-question-list" height={{ base: 150, tablet: 200 }} animation="wave" />
      }
      studentRevisionPanel={
        <Skeleton testHook="loader-student-revision-panel" height={{ base: 100, tablet: 150 }} animation="wave" />
      }
      testHook="overview-loader"
    />
  );
}
