// @flow
import { ApolloError, useQuery } from '@apollo/client';
import invariant from 'invariant';

import type { GetClassUsers, GetClassUsersVariables } from 'src/graphql/types/generated/GetClassUsers';
import type { UserAccountStatusType, UserGroupType } from 'src/types';

import GET_CLASS_USERS from './GetClassUsers.graphql';

type Props = {|
  classId: string,
  subscriptionId: string,
  userType: UserGroupType,
|};

export type ClassUser = {|
  accountStatus: UserAccountStatusType,
  avatar: ?string,
  color: number,
  email: string,
  firstName: ?string,
  id: string,
  lastLoggedInAt: ?string,
  lastName: ?string,
|};

type Output = {|
  data: ?{|
    className: string,
    isSetupMode: boolean,
    users: $ReadOnlyArray<ClassUser>,
  |},
  error: ?ApolloError,
  loading: boolean,
|};

export default function useGetClassUsers({ classId, subscriptionId, userType }: Props): Output {
  const { data, error, loading } = useQuery<GetClassUsers, GetClassUsersVariables>(GET_CLASS_USERS, {
    variables: {
      accountId: subscriptionId,
      classId,
      fetchStudents: userType === 'students',
    },
  });

  if (error) {
    return {
      data: null,
      error,
      loading: false,
    };
  }

  if (loading && !data) {
    return { data: null, error: null, loading: true };
  }

  const accountData = data?.me?.account;
  const classData = accountData?.class;
  invariant(accountData && classData, 'Class user data should be defined');

  const classUsers = classData.students ?? classData.teachers;

  return {
    data: {
      className: classData.name,
      isSetupMode: accountData.enabledFeatures.includes('SETUP_MODE'),
      users: classUsers.map((user) => ({
        accountStatus: user.accountStatus,
        avatar: user.avatar,
        color: user.color,
        email: user.email,
        firstName: user.firstName,
        id: user.id,
        lastLoggedInAt: user.lastLoggedInAt,
        lastName: user.lastName,
      })),
    },
    error: null,
    loading: false,
  };
}
