// @flow
import { getChallengeLabel } from 'src/utils/challenge';
import type { ChallengeTier } from 'src/types';

import { groupByCohort, cohorts } from '../utilities/studentCohorts';
import AverageMarkMetric, { type ChallengeAverageMark } from './AverageMarkMetric/AverageMarkMetric';
import ChallengingQuestionList from './ChallengingQuestionList/ChallengingQuestionList';
import CompletionRateMetric from './CompletionRateMetric/CompletionRateMetric';
import Header from '../Header/Header';
import StudentClassificationMetric from './StudentClassificationMetric/StudentClassificationMetric';
import StudentRevisionPanel from './StudentRevisionPanel/StudentRevisionPanel';
import Tabs from '../Tabs/Tabs';
import OverviewLayout from './OverviewLayout/OverviewLayout';
import type { ChallengingQuestion, OverviewStudent } from '../types';

type OverviewProps = {|
  averageMark: ChallengeAverageMark,
  challengeTier: ChallengeTier,
  challengingQuestions: $ReadOnlyArray<ChallengingQuestion>,
  completionRate: {|
    total: number,
    value: number,
  |},
  marksUrl: string,
  overviewUrl: string,
  shouldHideRevisionPanel?: boolean,
  students: $ReadOnlyArray<OverviewStudent>,
|};

export default function Overview(props: OverviewProps) {
  const {
    averageMark,
    challengeTier,
    challengingQuestions,
    completionRate,
    marksUrl,
    overviewUrl,
    shouldHideRevisionPanel,
    students,
  } = props;

  return (
    <OverviewLayout
      header={<Header heading={`How’s everyone going with this ${getChallengeLabel(challengeTier)}?`} />}
      tabs={<Tabs overviewUrl={overviewUrl} marksUrl={marksUrl} />}
      completionRateMetric={
        <CompletionRateMetric to={marksUrl} total={completionRate.total} value={completionRate.value} />
      }
      averageMarkMetric={<AverageMarkMetric to={marksUrl} deltas={averageMark.deltas} value={averageMark.value} />}
      studentClassificationMetrics={
        <>
          <StudentClassificationMetric
            students={groupByCohort(students, cohorts.NEEDS_HELP)}
            to={`${marksUrl}?filter=${cohorts.NEEDS_HELP}`}
            variant="needHelp"
          />
          <StudentClassificationMetric
            students={groupByCohort(students, cohorts.GOING_WELL)}
            to={`${marksUrl}?filter=${cohorts.GOING_WELL}`}
            variant="goingWell"
          />
        </>
      }
      challengingQuestionList={<ChallengingQuestionList data={challengingQuestions} />}
      studentRevisionPanel={
        shouldHideRevisionPanel ? null : (
          <StudentRevisionPanel
            students={groupByCohort(students, cohorts.NEEDS_REVISION)}
            to={`${marksUrl}?filter=${cohorts.NEEDS_REVISION}`}
          />
        )
      }
    />
  );
}
