// @flow
import { Box, Heading, useMediaQuery } from '@getatomi/neon';

type HeaderProps = {
  heading: string,
};

export default function Header(props: HeaderProps) {
  const { heading } = props;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <Box as="header" textAlign="center">
      <Heading as="h1" variant={isMobile ? 'large' : 'large1X'}>
        {heading}
      </Heading>
    </Box>
  );
}
