// @flow
import { useCallback, useState, useMemo } from 'react';
import { type ContextRouter, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import _ from 'lodash';
import invariant from 'invariant';
import {
  Box,
  Container,
  Flex,
  Heading,
  HideVisually,
  IconCalendar,
  InfoTip,
  StatusLight,
  Text,
  TextLoader,
  useMediaQuery,
} from '@getatomi/neon';

import type { DateString } from 'src/types';
import type {
  GetInsightsFilters,
  GetInsightsFiltersVariables,
  GetInsightsFilters_me_account_metrics_insights_Insight_classes as GetInsightsFiltersClass,
  GetInsightsFilters_me_account_metrics_insights_Insight_levels as GetInsightsFiltersLevel,
  GetInsightsFilters_me_account_metrics_insights_Insight_subjectGroups as GetInsightsFiltersSubjectGroup,
  GetInsightsFilters_me_account_metrics_insights_Insight_students as GetInsightsFiltersStudent,
} from 'src/graphql/types/generated/GetInsightsFilters';
import type {
  GetInsights,
  GetInsightsVariables,
  GetInsights_me_account_metrics_insights_Insight_dataPoints as DataPoint,
} from 'src/graphql/types/generated/GetInsights';
import type { InsightItemNodeTypeInput } from 'src/graphql/types/generated/GetInsightItems';
import insightTypes from 'src/constants/insightTypes';
import Date from 'src/components/Date/Date';
import { Dropdown, DropdownLoader, Item, ItemLabel } from 'src/components/Dropdown/Dropdown';
import FilterGroup from 'src/components/FilterGroup/FilterGroup';
import Link from 'src/components/Link/Link';
import UpgradeAccountPanel from 'src/domains/Class/UpgradeAccountPanel/UpgradeAccountPanel';
import { getUserId } from 'src/reducers/auth';
import {
  getActiveSubscriptionName,
  getActiveSubscriptionCreationDate,
  isFreePlan as isFreePlanSelector,
  isLoggedInAsParent as isLoggedInAsParentSelector,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
  isRetailPlan as isRetailPlanSelector,
} from 'src/reducers/subscriptions';
import { abbreviate } from 'src/utils/number';
import { getInsightsUrl } from 'src/utils/routes';
import insightItemNodeTypes from 'src/constants/insightItemNodeTypes';
import links from 'src/constants/links';
import userAccountStatuses from 'src/constants/userAccountStatuses';
import sortYearLevels from 'src/utils/sortYearLevels';

import GET_INSIGHTS_FILTERS from './GetInsightsFilters.graphql';
import GET_INSIGHTS from './GetInsights.graphql';
import StudentFilter from './StudentFilter/StudentFilter';
import ClassPicker from './ClassPicker/ClassPicker';
import {
  type DateRange,
  YearToDate,
  LastYear,
  QuarterToDate,
  LastQuarter,
  MonthToDate,
  LastMonth,
  WeekToDate,
  LastWeek,
  Last90Days,
  Last30Days,
  Last7Days,
} from './dateRanges';
import Insight, { InsightLoader } from './Insight';
import InsightGraph, { type PartialGraphProps } from './InsightGraph';
import InsightsTableQuery from './InsightsTableQuery';
import InsightsErrorBoundary from './InsightsErrorBoundary';
import styles from './Insights.module.scss';

const mediaQuery = { minWidth: 'breakpointMedium' };
const titlesForType = {
  // The order of these keys is the order in which the cards will be displayed
  [insightTypes.questions]: 'Questions answered',
  [insightTypes.lessons]: 'Lessons completed',
  [insightTypes.hours]: 'Hours spent',
};
const formatDate = (date) => moment(date).format('YYYY-MM-DD');
// used as a key on Filters to force a re-render of the MultiSelects when clearing all the filters
let filtersClearedCounter = 0;

export const dateRanges = {
  'Last 7 days': Last7Days,
  'Last 30 days': Last30Days,
  'Last 90 days': Last90Days,
  'This week': WeekToDate,
  'Last week': LastWeek,
  'Month to date': MonthToDate,
  'Last month': LastMonth,
  'Quarter to date': QuarterToDate,
  'Last quarter': LastQuarter,
  'Year to date': YearToDate,
  'Last year': LastYear,
};
export const DEFAULT_DATE_RANGE: $Keys<typeof dateRanges> = 'Last 90 days';

function useAggregateData(dataPoints: $ReadOnlyArray<DataPoint>, dateRange: DateRange) {
  return useMemo(() => {
    const result: {
      [key: $Keys<typeof insightTypes>]: {| count: number, countComparison: number |},
    } = {};
    if (!dataPoints) return result;
    Object.keys(insightTypes).forEach((type) => {
      result[type] = result[type] || { count: 0, countComparison: 0 };
      for (const dataPoint of dataPoints) {
        result[type][dateRange.isComparison(dataPoint.key) ? 'countComparison' : 'count'] += dataPoint[type];
      }
    });
    return result;
  }, [dataPoints, dateRange]);
}

function useGraphData(dataPoints: $ReadOnlyArray<DataPoint>, dateRange: DateRange) {
  return useMemo(() => {
    const toPair = (group: Array<DataPoint>): Array<?DataPoint> =>
      group.length === 1 // eslint-disable-line no-nested-ternary
        ? dateRange.isComparison(group[0].key)
          ? [group[0], null]
          : [null, group[0]]
        : [group[0], group[1]];
    const createPoint = (key, value): DataPoint => ({ key, ..._.mapValues(insightTypes, () => value) });
    const createGraphSeries = (points, type) =>
      points.map((point) => ({
        x: dateRange.formatKey(point.key),
        y: point[type],
      }));
    const createGraphData = (type: $Keys<typeof insightTypes>) => {
      const groups = _.groupBy(dataPoints, (dataPoint) => dateRange.keyBy(dataPoint.key));
      // filter out points that fall outside of our range e.g. if current period is 1-30 November
      // (30 days) and comparison is 1-31 October (31 days) => because we iterate over the days of
      // November we'll ignore 31 October
      const groupsFiltered: typeof groups = {};
      for (const key of dateRange.keysInRange()) {
        const groupKey: $Keys<typeof groups> = dateRange.keyBy(key);
        // we can have 3 combinations here:
        // - no comparison and no current data: group[groupKey] is undefined
        // - no comparison data: group[groupKey] is [comparison]
        // - no current data: group[groupKey] is [current]
        const [comparisonPoint, currentPoint] = toPair(groups[groupKey] || [null, null]);
        groupsFiltered[groupKey] = [
          // for missing data points (applies to both comparison and current):
          // - if it's a null data point then create an entry so it can be represented on the X axis
          // - otherwise create a zero point to show there was no usage
          comparisonPoint || (dateRange.isNullComparison(key) ? createPoint(key, null) : createPoint(key, 0)),
          currentPoint || (dateRange.isNull(key) ? createPoint(key, null) : createPoint(key, 0)),
        ];
      }
      // sort points by current key
      const groupsSorted: Array<Array<DataPoint>> = _.sortBy(_.values(groupsFiltered), '1.key');
      // convert from { [key]: [comparisonPoint, currentPoint] } to [Array<comparisonPoint>, Array<currentPoint>]
      const [comparison, current] = _.unzip(groupsSorted);
      return [
        {
          id: `${titlesForType[type]} (previous period)`,
          data: createGraphSeries(comparison, type),
        },
        { id: titlesForType[type], data: createGraphSeries(current, type) },
      ];
    };

    const result: {
      [type: $Keys<typeof insightTypes>]: $Call<typeof createGraphData, $Keys<typeof insightTypes>>,
    } = _.mapValues(insightTypes, (type) => createGraphData(type));

    return result;
  }, [dataPoints, dateRange]);
}

function Skeleton({
  cards,
  filters,
  children,
  updatedAt,
}: {
  cards: React$Node,
  children: React$Node,
  filters: React$Node,
  updatedAt: React$Node,
}) {
  return (
    <Container>
      <Box marginBottom={{ root: 'spacingSmall', tablet: 'spacingLarge6X' }} testHook="insights-header-media">
        <div className={styles.header} data-test="insights-header">
          <div className={styles.heading}>
            <Heading as="h1">Insights</Heading>
          </div>
          <div className={styles.updatedAt}>{updatedAt}</div>
          <div className={styles.filters}>{filters}</div>
        </div>
      </Box>
      <Box marginBottom={{ root: 'spacingLarge5X', tablet: 'spacingLarge2X' }} testHook="insights-cards-media">
        <div className={styles.cards} data-test="insights-cards">
          {cards}
        </div>
      </Box>
      <Box marginBottom={{ root: 'spacingLarge5X', tablet: 'spacingLarge7X' }} testHook="insights-children-media">
        {children}
      </Box>
    </Container>
  );
}

type FilterProps = {
  classIds: Array<string>,
  dateRangeName: $Keys<typeof dateRanges>,
  isLoggedInAsStudent: boolean,
  levelId: ?string,
  onClassIdsChange: (value: Array<string>) => mixed,
  onDateRangeNameChange: (value: $Keys<typeof dateRanges>) => mixed,
  onLevelIdChange: (value: ?string) => mixed,
  onStudentIdsChange: (value: Array<string>) => mixed,
  onSubjectGroupChange: (value: ?string) => mixed,
  subjectGroup: ?string,
};

export function sortByDeletedAt<T>(collection: $ReadOnlyArray<T & { +deletedAt: ?string }>): $ReadOnlyArray<T> {
  return _.sortBy(collection, (item) => Boolean(item.deletedAt));
}

function Filters({
  onClassIdsChange,
  data,
  subjectGroup,
  onSubjectGroupChange,
  levelId,
  classIds,
  onLevelIdChange,
  dateRangeName,
  onDateRangeNameChange,
  onStudentIdsChange,
  isRetailPlan,
  isLoggedInAsParent,
  isLoggedInAsStudent,
}: FilterProps & {
  data: {
    classes: $ReadOnlyArray<GetInsightsFiltersClass>,
    levels: ?$ReadOnlyArray<GetInsightsFiltersLevel>,
    students: ?$ReadOnlyArray<?GetInsightsFiltersStudent>,
    subjectGroups: ?$ReadOnlyArray<GetInsightsFiltersSubjectGroup>,
  },
  isLoggedInAsParent: boolean,
  isRetailPlan: boolean,
}) {
  const { classes, levels, students, subjectGroups } = data;
  const selectClassesOnChange = useCallback(
    (selectedClasses) => {
      onClassIdsChange(selectedClasses.map((c) => c.id.toString()));
    },
    [onClassIdsChange]
  );

  // memoise students to prevent unnecessary multiselect re-renders
  const subscriptionStudents = useMemo(() => {
    // using a Set to avoid saving similar errors and prevent reaching the sentry payload limit when
    // the error is logged
    const users = students
      ? students.filter(Boolean).map((student) => {
          return {
            accountStatus: userAccountStatuses.ACTIVE,
            avatar: student.avatarUrl,
            color: student.colorCode,
            deletedAt: student.deletedAt,
            email: student.email,
            firstName: student.firstName,
            id: student.id,
            lastName: student.lastName,
          };
        })
      : null;
    return users ? sortByDeletedAt(users) : null;
  }, [students]);

  const selectStudentsOnChange = useCallback(
    (selectedStudentIds) => {
      onStudentIdsChange(selectedStudentIds);
    },
    [onStudentIdsChange]
  );
  const matches = useMediaQuery(mediaQuery);
  const isScrollable = !isLoggedInAsStudent && !matches;

  const sortedClasses = useMemo(() => sortByDeletedAt(classes || []), [classes]);

  let classesFilter;
  // If classes is undefined it's because we're still loading them
  if (typeof classes === 'undefined') {
    classesFilter = <DropdownLoader variant="filter" animation="wave" />;
  } else {
    classesFilter = (
      <ClassPicker classes={sortedClasses} onChange={selectClassesOnChange} testHook="insights-filter-classIds" />
    );
  }

  const filters = (
    <FilterGroup label={matches ? 'Filter by' : undefined} isScrollable={isScrollable}>
      {levels && !isLoggedInAsParent && (
        <Dropdown
          ariaLabel="Filter by level"
          variant="filter"
          items={[
            { label: 'All years', value: null },
            ...sortYearLevels(levels, 'desc').map(({ id, name }) => ({ label: name, value: id })),
          ]}
          selectedKey={String(levelId)}
          onSelectionChange={(key) => onLevelIdChange(key === 'null' ? null : String(key))}
        >
          {(item) => <Item key={item.value}>{item.label}</Item>}
        </Dropdown>
      )}
      {subjectGroups && !isLoggedInAsParent && (
        <Dropdown
          ariaLabel="Filter by faculty"
          variant="filter"
          items={[
            { color: null, label: 'All faculties', value: null },
            ..._.sortBy(subjectGroups, 'name').map(({ code, color, name }) => ({
              color,
              label: name,
              value: code,
            })),
          ]}
          selectedKey={String(subjectGroup)}
          onSelectionChange={(key) => onSubjectGroupChange(key === 'null' ? null : String(key))}
        >
          {(item) => (
            <Item key={item.value} textValue={item.label}>
              {item.color && <StatusLight color={item.color} />}
              <ItemLabel>{item.label}</ItemLabel>
            </Item>
          )}
        </Dropdown>
      )}
      {isLoggedInAsStudent || isRetailPlan ? (
        <Dropdown
          ariaLabel={isRetailPlan ? 'Filter by subject' : 'Filter by class'}
          variant="filter"
          items={[
            {
              color: null,
              label: isRetailPlan ? 'All subjects' : 'All classes',
              value: null,
            },
            ..._.sortBy(classes, 'name').map(({ id, subject }) => ({
              color: subject.color,
              label: subject.name,
              value: id,
            })),
          ]}
          selectedKey={String(classIds[0] ?? null)}
          onSelectionChange={(key) => onClassIdsChange(key === 'null' ? [] : [String(key)])}
        >
          {(item) => (
            <Item key={item.value} textValue={item.label}>
              {item.color && <StatusLight color={item.color} />}
              <ItemLabel>{item.label}</ItemLabel>
            </Item>
          )}
        </Dropdown>
      ) : (
        classesFilter
      )}
      {typeof students === 'undefined' && !isLoggedInAsParent && !isLoggedInAsStudent && (
        <DropdownLoader variant="filter" animation="wave" />
      )}
      {subscriptionStudents && !isLoggedInAsParent && (
        <StudentFilter
          students={subscriptionStudents}
          onChange={selectStudentsOnChange}
          searchEmptyState="No students found. The student you’re searching for may not have any usage during this period."
          testHook="insights-filter-studentIds"
        />
      )}
      <Dropdown
        ariaLabel="Filter by date range"
        variant="filter"
        items={Object.keys(dateRanges).map((value) => ({
          label: value,
          value,
        }))}
        selectedKey={dateRangeName}
        onSelectionChange={onDateRangeNameChange}
      >
        {(item) => (
          <Item key={item.value} textValue={item.label}>
            <Box as="span" marginRight="spacingSmall1X">
              <IconCalendar color="colorIconSubtle" size="sizeIconSmall1X" />
            </Box>
            <ItemLabel>{item.label}</ItemLabel>
          </Item>
        )}
      </Dropdown>
    </FilterGroup>
  );

  return filters;
}
Filters.Loader = function FiltersLoader({ count }: { count: number }) {
  return (
    <FilterGroup>
      {_.times(count, (i) => (
        <DropdownLoader key={i} variant="filter" animation="wave" />
      ))}
    </FilterGroup>
  );
};
Filters.Loader.displayName = 'Filters.Loader';

function Cards({
  dataPoints,
  insightType,
  onClick,
  dateRange,
  graphProps,
  loading,
}: {
  dataPoints: $ReadOnlyArray<DataPoint>,
  dateRange: DateRange,
  graphProps: PartialGraphProps,
  insightType: $Keys<typeof insightTypes>,
  loading: boolean,
  onClick: (type: $Keys<typeof insightTypes>) => mixed,
}) {
  const { startDate, endDate, description } = dateRange;
  const aggregates = useAggregateData(dataPoints, dateRange);
  const graphData = useGraphData(dataPoints, dateRange);

  return loading ? (
    <Cards.Loader />
  ) : (
    <>
      {Object.keys(titlesForType).map((type) => {
        const { count, countComparison } = aggregates[type];
        return (
          <div key={type} className={styles.card}>
            <Insight
              title={titlesForType[type]}
              diffDescription={description}
              description={`${moment(startDate).format('D MMM YYYY')} – ${moment(endDate).format('D MMM YYYY')}`}
              diffValue={count === 0 && countComparison === 0 ? 0 : count / countComparison - 1} // allow Infinity%
              value={abbreviate(count)}
              graphProps={{
                ...graphProps,
                data: graphData[type],
              }}
              onClick={() => onClick(type)}
              isActive={type === insightType}
              testHook={`insights-card-${type}`}
            />
          </div>
        );
      })}
    </>
  );
}
Cards.Loader = function CardsLoader() {
  return (
    <>
      {Object.keys(titlesForType).map((type) => (
        <div key={type} className={styles.card}>
          <InsightLoader />
        </div>
      ))}
    </>
  );
};
Cards.Loader.displayName = 'Cards.Loader';

function Graph({
  dataPoints,
  insightType,
  dateRange,
  graphProps,
  loading,
}: {
  dataPoints: $ReadOnlyArray<DataPoint>,
  dateRange: DateRange,
  graphProps: PartialGraphProps,
  insightType: $Keys<typeof insightTypes>,
  loading: boolean,
}) {
  const graphData = useGraphData(dataPoints, dateRange);
  const matches = useMediaQuery(mediaQuery);

  return loading ? (
    <Graph.Loader />
  ) : (
    matches && <InsightGraph {...graphProps} data={graphData[insightType]} testHook="insights-graph" />
  );
}
Graph.Loader = function GraphLoader() {
  const matches = useMediaQuery(mediaQuery);
  return (
    matches && (
      <div className={styles.graphLoading}>
        <div className={styles.legend}>
          <TextLoader />
        </div>
        <div>
          <TextLoader height={120} />
          <TextLoader height={120} />
        </div>
      </div>
    )
  );
};
Graph.Loader.displayName = 'Graph.Loader';

function Loader({ filtersCount }: { filtersCount: number }) {
  return (
    <Skeleton
      updatedAt={
        <div className={styles.updatedAtLoader}>
          <TextLoader />
        </div>
      }
      filters={<Filters.Loader count={filtersCount} />}
      cards={<Cards.Loader />}
    >
      <Graph.Loader />
    </Skeleton>
  );
}

type InjectedProps = {
  // eslint-disable-line react/no-unused-prop-types
  isFreePlan: boolean, // eslint-disable-line react/no-unused-prop-types
  isLoggedInAsParent: boolean,
  isLoggedInAsStudent: boolean,
  isRetailPlan: boolean, // eslint-disable-line react/no-unused-prop-types
  params: {
    insightType: $Keys<typeof insightTypes>,
    subscriptionId: string,
  },
  router: ContextRouter,
  subscriptionName: string, // eslint-disable-line react/no-unused-prop-types
  subscriptionStartDate: DateString, // eslint-disable-line react/no-unused-prop-types
  userId: string, // eslint-disable-line react/no-unused-prop-types
};

export function InsightsQuery(
  props: InjectedProps &
    FilterProps & {
      classIds: Array<string>,
      dateRange: DateRange,
      studentIds: Array<string>,
    }
) {
  const {
    classIds,
    dateRange,
    dateRangeName,
    subjectGroup,
    isLoggedInAsStudent,
    isLoggedInAsParent,
    isRetailPlan,
    levelId,
    studentIds,
    onClassIdsChange,
    onDateRangeNameChange,
    onSubjectGroupChange,
    onLevelIdChange,
    onStudentIdsChange,
    params: { subscriptionId, insightType },
    router,
  } = props;

  const graphProps = useMemo(
    () => ({
      renderValue: abbreviate,
      renderTooltipContent: ({ label, payload: [comparison, current] }) => (
        <>
          <Text fontSize="fontSizeSmall">{label}</Text>
          {current && (
            <Text variant="bodySmall1X" color={current.color}>
              {current.name}: {abbreviate(current.value)}
            </Text>
          )}
          {comparison && <Text variant="bodySmall1X">Previous period: {abbreviate(comparison.value)}</Text>}
        </>
      ),
      xAxisProps: [MonthToDate, LastMonth, Last30Days].includes(dateRange.constructor)
        ? { interval: 'preserveStart', minTickGap: 5 }
        : undefined,
    }),
    [dateRange]
  );

  const { keyFormat, startDate, endDate, comparisonStartDate, comparisonEndDate } = dateRange;
  const filters = {
    keyFormat,
    // We don't allow showing data older than last year, which includes a YOY comparison with the year before
    insightEntitiesDateFrom: formatDate(moment().subtract(2, 'years').startOf('year')),
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    comparisonStartDate: formatDate(comparisonStartDate),
    comparisonEndDate: formatDate(comparisonEndDate),
    classIds,
  };

  const insightsFilters = useQuery<GetInsightsFilters, GetInsightsFiltersVariables>(GET_INSIGHTS_FILTERS, {
    variables: ({
      accountId: subscriptionId,
      isStudent: isLoggedInAsStudent,
      // Selecting a filter doesn't change the options returned, so memoizing to prevent this
      // expensive query from being repeatedly triggered
      filters: useMemo(() => filters, []), // eslint-disable-line react-hooks/exhaustive-deps
    }: // manually cast to catch any typos
    $Exact<GetInsightsFiltersVariables>),
  });

  const insights = useQuery<GetInsights, GetInsightsVariables>(GET_INSIGHTS, {
    variables: ({
      accountId: subscriptionId,
      isStudent: isLoggedInAsStudent,
      filters: {
        ...filters,
        levelId,
        subjectGroup,
        userIds: studentIds,
      },
    }: // manually cast to catch any typos
    $Exact<GetInsightsVariables>),
  });

  const error = insightsFilters.error || insights.error;
  const { loading } = insights;
  if (error) throw error;

  if (insightsFilters.loading && !insightsFilters.data)
    return <Loader filtersCount={isLoggedInAsStudent || isLoggedInAsParent ? 2 : 5} />;

  let dataPoints = [];
  const insightsData = insights?.data?.me?.account.metrics ?? insights?.previousData?.me?.account.metrics;
  if (insightsData && insightsData.insights) {
    ({ dataPoints } = insightsData.insights);
  }

  const insightsFiltersData =
    insightsFilters.data?.me?.account.metrics ?? insightsFilters.previousData?.me?.account.metrics;

  invariant(insightsFiltersData && insightsFiltersData.insights, 'Insights filter data should be available');

  let levels;
  let students;
  let subjectGroups;
  if (insightsFiltersData.insights.__typename === 'Insight') {
    ({ levels, students, subjectGroups } = insightsFiltersData.insights);
  }

  const { classes, updatedAt } = insightsFiltersData.insights;
  const filtersData = {
    classes,
    levels,
    students,
    subjectGroups,
  };

  return (
    <Skeleton
      updatedAt={
        <Flex justifyContent="flex-end" alignItems="center" testHook="hide-in-percy">
          <Text variant="bodySmall1X" color="colorTextSubtler">
            Updated <Date value={updatedAt} isRelative isSentenceCase={false} />
          </Text>
          <InfoTip
            content={
              <>
                Insights data may be delayed by up to 36 hours. The last update was on{' '}
                <Date value={updatedAt} format="D MMM YYYY [at] HH:mm" />
              </>
            }
            iconSize="sizeIconSmall2X"
          />
        </Flex>
      }
      filters={
        <Filters
          key={filtersClearedCounter}
          data={filtersData}
          subjectGroup={subjectGroup}
          levelId={levelId}
          dateRangeName={dateRangeName}
          classIds={classIds}
          onSubjectGroupChange={onSubjectGroupChange}
          onLevelIdChange={onLevelIdChange}
          onDateRangeNameChange={onDateRangeNameChange}
          onStudentIdsChange={onStudentIdsChange}
          onClassIdsChange={onClassIdsChange}
          isLoggedInAsStudent={isLoggedInAsStudent}
          isRetailPlan={isRetailPlan}
          isLoggedInAsParent={isLoggedInAsParent}
        />
      }
      cards={
        <Cards
          loading={loading}
          dataPoints={dataPoints}
          dateRange={dateRange}
          insightType={insightType}
          graphProps={graphProps}
          onClick={(type) => router.replace(getInsightsUrl(subscriptionId, type))}
        />
      }
    >
      <Graph
        loading={loading}
        dataPoints={dataPoints}
        dateRange={dateRange}
        insightType={insightType}
        graphProps={graphProps}
      />
    </Skeleton>
  );
}

function Insights(props: InjectedProps) {
  const {
    isLoggedInAsParent,
    isFreePlan,
    isLoggedInAsStudent,
    isRetailPlan,
    subscriptionName,
    subscriptionStartDate,
    userId,
  } = props;

  let defaultNodeType = insightItemNodeTypes.user;
  if (isLoggedInAsStudent) {
    defaultNodeType = insightItemNodeTypes.class;
  } else if (isLoggedInAsParent) {
    defaultNodeType = insightItemNodeTypes.subject;
  }

  const [subjectGroup, setSubjectGroup] = useState<?string>(null);
  const [levelId, setLevelId] = useState<?string>(null);
  const [classIds, setClassIds] = useState<Array<string>>([]);
  const [studentIds, setStudentIds] = useState<Array<string>>([]);
  const [dateRangeName, setDateRangeName] = useState<$Keys<typeof dateRanges>>(DEFAULT_DATE_RANGE);
  const [tableNodeType, setTableNodeType] = useState<InsightItemNodeTypeInput>(defaultNodeType);
  const dateRange = useMemo<DateRange>(
    () => new dateRanges[dateRangeName](subscriptionStartDate),
    [dateRangeName, subscriptionStartDate]
  );

  const resetFilters = () => {
    filtersClearedCounter++;
    setSubjectGroup(null);
    setLevelId(null);
    setClassIds([]);
    setStudentIds([]);
    setDateRangeName(DEFAULT_DATE_RANGE);
  };

  const title = isLoggedInAsStudent || isLoggedInAsParent ? 'Insights' : `Insights for ${subscriptionName}`;

  return (
    <InsightsErrorBoundary>
      <Helmet title={title} />
      <HideVisually>
        <h1>{title}</h1>
      </HideVisually>
      {isFreePlan ? (
        <UpgradeAccountPanel
          illustrationName="emptystate-insights"
          heading={
            // eslint-disable-next-line no-nested-ternary
            isLoggedInAsParent
              ? 'Want to view insights for your account?'
              : isLoggedInAsStudent
              ? 'Want to view insights for your usage?'
              : 'Want to view insights for your school?'
          }
          description={
            // eslint-disable-next-line no-nested-ternary
            isLoggedInAsParent
              ? 'When you upgrade to unlimited access, you can view data and analytics of your child’s usage over time to see how they’re tracking.'
              : isLoggedInAsStudent
              ? 'When you upgrade to unlimited access, you can view data and analytics of your Atomi usage over time to see how you’re tracking towards your usage goals.'
              : 'With Atomi Insights you can discover how your students are using Atomi over time. These insights can be a powerful tool to identify trends, discover opportunities or compare usage by year, faculty, subject, class or student.'
          }
          descriptionLink={
            <Link
              href={
                isLoggedInAsStudent // eslint-disable-line no-nested-ternary
                  ? links.support.insights.student
                  : isLoggedInAsParent
                  ? links.support.insights.parent
                  : links.support.insights.school
              }
              isExternal
            >
              See how Atomi Insights work in more detail
            </Link>
          }
        />
      ) : (
        <>
          <InsightsQuery
            {...props}
            dateRange={dateRange}
            dateRangeName={dateRangeName}
            subjectGroup={subjectGroup}
            onDateRangeNameChange={setDateRangeName}
            levelId={levelId}
            onSubjectGroupChange={setSubjectGroup}
            onLevelIdChange={setLevelId}
            studentIds={studentIds}
            onStudentIdsChange={setStudentIds}
            classIds={classIds}
            onClassIdsChange={setClassIds}
            isLoggedInAsStudent={isLoggedInAsStudent}
            isRetailPlan={isRetailPlan}
            isLoggedInAsParent={isLoggedInAsParent}
          />
          <InsightsTableQuery
            // using key to reset component state when filters change
            key={`table-${String(subjectGroup)}-${String(levelId)}-${dateRangeName}`}
            {...props}
            subscriptionId={props.params.subscriptionId}
            startDate={formatDate(dateRange.startDate)}
            endDate={formatDate(dateRange.endDate)}
            subjectGroup={subjectGroup}
            levelId={levelId}
            classIds={classIds}
            studentIds={studentIds}
            nodeType={tableNodeType}
            onNodeTypeChange={setTableNodeType}
            onResetFilters={resetFilters}
            userId={isLoggedInAsStudent ? userId : undefined}
            withGroupingFilters={!isLoggedInAsParent}
          />
        </>
      )}
    </InsightsErrorBoundary>
  );
}

export default withRouter(
  connect((state) => ({
    isLoggedInAsStudent: isLoggedInAsStudentSelector(state),
    isLoggedInAsParent: isLoggedInAsParentSelector(state),
    isFreePlan: isFreePlanSelector(state),
    isRetailPlan: isRetailPlanSelector(state),
    subscriptionName: getActiveSubscriptionName(state),
    subscriptionStartDate: getActiveSubscriptionCreationDate(state),
    userId: getUserId(state).toString(),
  }))(Insights)
);
