// @flow
import { Box, Flex, Stack } from '@getatomi/neon';

type MarksLayoutProps = {|
  body: React.Node,
  filter: React.Node,
  header: React.Node,
  tabs: React.Node,
|};

export default function MarksLayout(props: MarksLayoutProps) {
  const { header, tabs, filter, body } = props;

  return (
    <Box paddingBottom={{ base: 'spacingRoot', tablet: 'spacingLarge3X' }}>
      <Stack spacing="spacingLarge5X">
        {header}
        {tabs}
      </Stack>
      <Flex
        direction="row"
        justifyContent="flex-end"
        paddingInline={{ base: 'spacingRoot', tablet: 'spacingLarge3X' }}
        marginBottom="spacingLarge2X"
      >
        {filter}
      </Flex>
      {body}
    </Box>
  );
}
