// @flow
import { forwardRef, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { useToast } from '@getatomi/neon';

import type { SignFileUpload, SignFileUploadVariables } from 'src/graphql/types/generated/SignFileUpload';
import Logger from 'src/utils/Logger';
import { convertSizeToMB, getFileExtension, uploadFile } from 'src/utils/file';
import { taskAttachmentTypes, taskUploadMaxFileSize } from 'src/constants/taskAttachment';

import SIGN_FILE_UPLOAD from './SignFileUpload.graphql';

const log = new Logger('domains/shared/TaskDialog/UploadFile');

export type UploadingFile = {
  externalId: string,
  fileName: string,
  isUploading: boolean,
  type: 'FILE',
};

export type UploadedFile = {
  ...$Exact<UploadingFile>,
  url: string,
};

type Props = {
  onError: (id: string) => mixed,
  onSuccess: (attachment: UploadedFile) => mixed,
  onUploading: (attachment: UploadingFile) => mixed,
  subscriptionId: string,
  testHook?: string,
};

export default forwardRef<Props, React.Node>(function UploadFile(props: Props, ref) {
  const { onError, onUploading, onSuccess, subscriptionId, testHook } = props;
  const formRef = useRef<null | HTMLFormElement>(null);
  const toast = useToast();

  const [signFileUpload] = useMutation<SignFileUpload, SignFileUploadVariables>(SIGN_FILE_UPLOAD);

  const resetForm = () => {
    // eslint-disable-next-line no-unused-expressions
    formRef && formRef.current && formRef.current.reset();
  };

  const onErrorHandler = ({ id, message }: { id?: string, message: string }) => {
    toast.error(message);
    if (id) onError(id);
  };

  const onChangeHandler = async (e: SyntheticEvent<HTMLInputElement>) => {
    const [file] = e.currentTarget.files;
    if (!file) return;

    const { name, size, type: contentType } = file;

    if (!contentType) {
      resetForm();
      return onErrorHandler({ message: 'This file type is not supported.' });
    }

    if (size > taskUploadMaxFileSize) {
      resetForm();
      return onErrorHandler({
        message: `This file is too large (${convertSizeToMB(size)}). The maximum file size is ${convertSizeToMB(
          taskUploadMaxFileSize
        )}`,
      });
    }

    const id = uuidv4();
    const ext = getFileExtension(name);
    const attachment = { externalId: id, type: taskAttachmentTypes.FILE, fileName: name, isUploading: true };
    // return attachment with loading state
    onUploading(attachment);

    try {
      // get signature to upload to s3
      const response = await signFileUpload({
        variables: {
          input: {
            contentType,
            ext,
            subscriptionId: subscriptionId.toString(),
          },
        },
      });
      if (!response.data) {
        throw new Error('No data returned from signFileUpload');
      }
      const {
        signFileUpload: { signedUpload, signedUrl },
      } = response.data;
      // upload file to s3
      await uploadFile({ signedUpload, file });
      // reset input file in case the user selects the same file twice in a row
      resetForm();
      onSuccess({
        ...attachment,
        url: signedUrl,
        isUploading: false,
      });
    } catch (error) {
      log.error('Error uploading file', error);
      resetForm();
      onErrorHandler({ message: `The file couldn’t be uploaded, try again.`, id });
    }
  };

  return (
    <form ref={formRef} hidden data-test={testHook}>
      {/* $FlowFixMe */}
      <input ref={ref} type="file" onChange={onChangeHandler} data-test={testHook && `${testHook}-input`} />
    </form>
  );
});
