// @flow

import type {
  GetRevisionMarks_me_account_class_revision_ClassRevision_items as GqlRevisionItem,
  GetRevisionMarks_me_account_class_revision_ClassRevision_latestAttempts_itemAttempts as GqlRevisionItemAttempt,
} from 'src/graphql/types/generated/GetRevisionMarks';
import type {
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_challenge_items as GqlChallengeItem,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_latestAttempts_itemAttempts as GqlChallengeItemAttempt,
} from 'src/graphql/types/generated/GetChallengeMarks';

export type QuestionResult = {|
  result: string | null,
  status: 'success' | 'error',
  type: 'default' | 'self-marked' | 'multiple-choice',
|};

export type EmptyQuestionResult = {|
  result: null,
  status: null,
  type: null,
|};

export type IndividualQuestionResult = QuestionResult | EmptyQuestionResult;

// An `empty` is visually represented as a dash
export const empty: EmptyQuestionResult = {
  result: null,
  status: null,
  type: null,
};

function getMultipleChoiceAttemptResult(answerId: string, options: $ReadOnlyArray<{ +id: string }>): string | null {
  const multipleChoiceLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const answerIndex = options.findIndex((option) => option.id === answerId);

  if (answerIndex === -1) {
    return null;
  }

  return multipleChoiceLetters[answerIndex] ?? '';
}

export function getIndividualQuestionResult(
  studentAttempt: ?GqlChallengeItemAttempt | ?GqlRevisionItemAttempt,
  item: GqlChallengeItem | GqlRevisionItem
): IndividualQuestionResult {
  if (!studentAttempt) {
    return empty;
  }

  switch (studentAttempt.__typename) {
    case 'SkippedQuestionAttempt':
      return empty;

    case 'DragAndDropQuestionAttempt':
    case 'ExactAnswerQuestionAttempt':
      return {
        result: null,
        status: studentAttempt.marking.isCorrect ? 'success' : 'error',
        type: 'default',
      };

    case 'MultipleChoiceQuestionAttempt': {
      if (item.__typename !== 'MultipleChoiceQuestion') {
        return empty;
      }

      const result = getMultipleChoiceAttemptResult(studentAttempt.selectedOptionId, item.options);

      if (result === null) {
        return empty;
      }

      return {
        result,
        status: studentAttempt.marking.isCorrect ? 'success' : 'error',
        type: 'multiple-choice',
      };
    }

    case 'SelfMarkedLiteQuestionAttempt':
    case 'SelfMarkedQuestionAttempt': {
      const { marking } = studentAttempt;

      return {
        result: `${marking.mark}/${marking.maxMarks}`,
        status: marking.isCorrect ? 'success' : 'error',
        type: 'self-marked',
      };
    }

    case 'TextSnippetAttempt':
    case 'VideoSnippetAttempt':
      return {
        result: null,
        status: 'success',
        type: 'default',
      };
    default:
      throw new Error(`Unexpected studentAttempt.__typename: ${studentAttempt.__typename}`);
  }
}
