// @flow
import { Box, Container, Skeleton } from '@getatomi/neon';

export default function TaskContainerLoader() {
  return (
    <>
      <Box display={{ base: 'none', tablet: 'block' }} paddingBottom={{ base: 'none', tablet: 'spacingLarge5X' }}>
        <Container>
          <Skeleton animation="wave" width={200} height={28} />
        </Container>
      </Box>
      <Container maxWidth="sizeContainerRoot">
        <Skeleton animation="wave" width={700} height={150} />
      </Container>
    </>
  );
}
