// @flow
import { Box, Container, Flex, Skeleton, Stack, TextLoader } from '@getatomi/neon';

export default function ModuleLoader() {
  return (
    <Container paddingInline="spacingNone">
      <TextLoader
        display={{ base: 'none', tablet: 'block' }}
        height={28}
        marginBottom="spacingLarge4X"
        marginLeft="spacingRoot"
        width={180}
      />
      <Container maxWidth="sizeContainerRoot" paddingInline="spacingNone">
        <Flex alignItems="baseline" justifyContent="space-between" marginInline="spacingRoot">
          <TextLoader height={40} width={300} marginTop="spacingSmall" marginBottom="spacingLarge2X" />
          <Skeleton animation="wave" height={{ base: 42, tablet: 37 }} width={{ base: 42, tablet: 114 }} />
        </Flex>

        <Flex alignItems="center" gap="spacingLarge" marginBlock="spacingLarge" marginLeft="spacingLarge">
          <Skeleton animation="wave" width={15} height={15} borderRadius="borderRadiusCircle" />

          <TextLoader height={30} width={180} />
        </Flex>

        <Stack as="ol" spacing="spacingNegativeSmall4X">
          {Array.from({ length: 5 }).map((_loaderValue, loaderIndex) => (
            <Flex
              key={loaderIndex}
              alignItems="center"
              gap="spacingRoot"
              padding="spacingRoot"
              borderWidth="borderWidthRoot"
              borderStyle="solid"
              borderColor="colorBorderNeutral"
              testHook="loading-lesson"
            >
              <Skeleton animation="wave" width={20} height={20} />

              <Box flex={1}>
                <TextLoader width={130} height={15} marginBottom="spacingSmall2X" />
                <TextLoader width={100} height={13} />
              </Box>
            </Flex>
          ))}
        </Stack>
      </Container>
    </Container>
  );
}
