// @flow
import invariant from 'invariant';
import { useQuery, ApolloError } from '@apollo/client';

import type { GetRevisionMarks, GetRevisionMarksVariables } from 'src/graphql/types/generated/GetRevisionMarks';
import postTypes from 'src/constants/postTypes';
import { getRevisionUrl } from 'src/utils/routes';
import type { MarksStudent } from 'src/domains/ViewingQuizResults/types';
import type { LessonMetrics } from 'src/domains/ViewingQuizResults/Marks/StudentStrength/StudentStrength';
import type { NewAttachedContent } from 'src/domains/Tasks/types';
import challengeTiers from 'src/constants/challengeTiers';

import GET_REVISION_MARKS from './GetRevisionMarks.graphql';
import { getRevisionQuestionUrlFactory } from './utilities/getQuestionUrlFactory';
import { prepareRevisionStudents } from './utilities/prepareMarksData/prepareMarksData';

type Props = {|
  accountId: string,
  classId: string,
  revisionId: string,
|};

export type PreparedMarksData = {|
  classData: {|
    id: string,
    name: string,
    subject: {|
      color: string,
      groupCode: string,
      name: string,
    |},
  |},
  revision: {|
    ...$Exact<NewAttachedContent>,
    averageMarkMetrics: LessonMetrics,
  |},
  sequence: $ReadOnlyArray<string>,
  students: $ReadOnlyArray<MarksStudent>,
|};

type Output = {|
  data: ?PreparedMarksData,
  error: ?ApolloError,
  loading: boolean,
|};

export function useGetRevisionMarks(props: Props): Output {
  const { accountId, classId, revisionId } = props;

  const { data, loading, error } = useQuery<GetRevisionMarks, GetRevisionMarksVariables>(GET_REVISION_MARKS, {
    variables: {
      accountId,
      classId,
      revisionId,
    },
  });

  if (error || (loading && !data)) {
    return { data: undefined, error, loading };
  }

  const getRevisionQuestionUrl = getRevisionQuestionUrlFactory({
    accountId,
    classId,
    challengeId: revisionId,
    returnTo: `${window.location.pathname}${window.location.search}`,
  });

  invariant(data, 'Query data should be defined');

  const accountData = data.me?.account;
  const classData = accountData?.class;
  invariant(
    accountData && classData && classData.revision && classData.revision.__typename === 'ClassRevision',
    'Class revision data should be defined'
  );
  const { revision, subject } = classData;
  const { classAverageMark } = revision.metrics.assessment;

  return {
    data: {
      revision: {
        id: revision.id,
        duration: revision.duration,
        name: revision.title,
        subjectCode: subject.code,
        tier: challengeTiers.TIER_4_REVISION,
        type: postTypes.revision,
        url: getRevisionUrl(accountId, classId, revisionId),
        averageMarkMetrics: {
          classAverageMark,
        },
      },
      classData: {
        id: classData.id,
        name: classData.name,
        subject: {
          color: subject.color,
          groupCode: subject.groupCode,
          name: subject.name,
        },
      },
      sequence: revision.items.map<string>((item) => item.id),
      students: prepareRevisionStudents(
        classData.students,
        revision.latestAttempts,
        revision.metrics,
        revision.items,
        getRevisionQuestionUrl
      ),
    },
    error,
    loading,
  };
}
