// @flow
import { browserHistory } from 'react-router';

import {
  getClassModuleUrl,
  getClassTasksUrl,
  getClassTopicInsightsUrl,
  getMarkbookMarksUrl,
  getPostUrl,
  getTasksUrl,
  getTaskUrl,
  getRevisionPageUrl,
} from 'src/utils/routes';

import { type NavigationContext } from './navigationContext';

function getBackLinkUrl(context: NavigationContext) {
  switch (context.location) {
    case 'module': {
      const { accountId, classId, moduleId } = context.metadata;
      return getClassModuleUrl(accountId, classId, moduleId);
    }
    case 'post': {
      const { accountId, classId, moduleId, lessonId } = context.metadata;
      return getPostUrl(accountId, classId, moduleId, lessonId);
    }
    case 'classTasks': {
      const { accountId, classId, status } = context.metadata;
      return getClassTasksUrl(accountId, classId, status);
    }
    case 'studentTasks': {
      const { accountId, isComplete } = context.metadata;
      return getTasksUrl(accountId, isComplete ? 'done' : undefined);
    }
    case 'task': {
      const { accountId, classId, taskId } = context.metadata;
      return getTaskUrl(accountId, classId, taskId);
    }
    case 'classTopicInsights': {
      const { accountId, classId, topicId } = context.metadata;
      return getClassTopicInsightsUrl(accountId, classId, topicId);
    }
    case 'studentRevisionPage': {
      const { accountId } = context.metadata;
      return getRevisionPageUrl(accountId);
    }
    case 'markbookMarks': {
      const { accountId, classId } = context.metadata;
      return getMarkbookMarksUrl(accountId, classId);
    }
    default: {
      return null;
    }
  }
}

export function prepareNavigation(context: NavigationContext) {
  const backLinkUrl = getBackLinkUrl(context) ?? '/';
  const backLinkLabel = context.metadata.title;

  function goBack() {
    browserHistory.push(backLinkUrl);
  }

  return { goBack, backLinkUrl, backLinkLabel };
}
