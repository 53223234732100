// @flow
import invariant from 'invariant';
import { Container, Skeleton } from '@getatomi/neon';
import Helmet from 'react-helmet';

import { getRevisionUrl, getRevisionMarksUrl, getRevisionProgressReportUrl } from 'src/utils/routes';
import ClassBreadcrumbs, { BreadcrumbsItem } from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';

import Marks from './Marks/Marks';
import MarksLoader from './Marks/MarksLoader';
import { useGetRevisionMarks } from './useGetRevisionMarks';

export type RevisionMarksContainerProps = {|
  params: {|
    classId: string,
    revisionId: string,
    subscriptionId: string,
  |},
|};

export function RevisionMarksContainer(props: RevisionMarksContainerProps) {
  const { params } = props;
  const { classId, revisionId, subscriptionId: accountId } = params;

  const revisionUrl = getRevisionUrl(accountId, classId, revisionId);
  const marksUrl = getRevisionMarksUrl(accountId, classId, revisionId);
  const overviewUrl = getRevisionProgressReportUrl(accountId, classId, revisionId);

  const { data, loading } = useGetRevisionMarks({
    accountId,
    classId,
    revisionId,
  });

  if (loading && !data) {
    return (
      <>
        <Container marginBottom="spacingLarge3X">
          <Skeleton height={28} width={300} />
        </Container>
        <MarksLoader
          heading="How’s everyone going with this revision?"
          isRevision
          marksUrl={marksUrl}
          overviewUrl={overviewUrl}
        />
      </>
    );
  }

  invariant(data, 'VQR marks data should be defined');
  const { classData, revision, sequence, students } = data;

  return (
    <>
      <Helmet>
        <title>
          {revision.name} | {classData.subject.name}
        </title>
      </Helmet>
      <Container marginBottom="spacingLarge3X">
        <ClassBreadcrumbs
          currentClass={classData}
          // Retail plans don't access VQR
          isRetailPlan={false}
          subject={classData.subject}
          subscriptionId={accountId}
        >
          <BreadcrumbsItem href={revisionUrl}>{revision.name}</BreadcrumbsItem>
        </ClassBreadcrumbs>
      </Container>
      <Marks
        attachedContent={revision}
        averageMarkMetrics={revision.averageMarkMetrics}
        classId={classId}
        isRevision
        marksUrl={marksUrl}
        overviewUrl={overviewUrl}
        sequence={sequence}
        students={students}
        subscriptionId={accountId}
      />
    </>
  );
}

export default RevisionMarksContainer;
