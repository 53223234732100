// @flow
import { DropdownLoader, HideVisually, Scrollable, Table, useTableSelection } from '@getatomi/neon';

import Header from '../Header/Header';
import Tabs from '../Tabs/Tabs';
import MarksLayout from './MarksLayout';
import prepareMarksColumns from '../utilities/prepareMarksColumns/prepareMarksColumns';

type MarksLoaderProps = {|
  heading: string,
  isRevision: boolean,
  marksUrl: string,
  overviewUrl: string,
|};

export default function MarksLoader(props: MarksLoaderProps) {
  const { heading, isRevision, marksUrl, overviewUrl } = props;

  const visibleLoadingRows = 4;
  const placeholderData = Array.from({ length: visibleLoadingRows }, () => ({}));

  // Required for the selection column to show
  const { tableProps } = useTableSelection({
    data: [],
  });

  const columns = prepareMarksColumns({
    averageMarkMetrics: {
      regionAverageMark: 0,
      schoolAverageMark: 0,
      classAverageMark: 0,
    },
    sequence: [],
    shouldHideStrength: isRevision,
  });

  return (
    <MarksLayout
      header={<Header heading={heading} />}
      tabs={<Tabs overviewUrl={overviewUrl} marksUrl={marksUrl} />}
      filter={<DropdownLoader variant="filter" />}
      body={
        <Scrollable direction="horizontal">
          <HideVisually>Loading the progress report</HideVisually>
          <Table {...tableProps} columns={columns} data={placeholderData} isLoading isStickyHeadings isSortable />
        </Scrollable>
      }
    />
  );
}
