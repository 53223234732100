// @flow
import { useMutation } from '@apollo/client';

import type {
  ResendStudentInvites,
  ResendStudentInvitesVariables,
  SendAccountInvitesInput,
} from 'src/graphql/types/generated/ResendStudentInvites';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';

import RESEND_STUDENT_INVITES from './ResendStudentInvites.graphql';

export default function useResendStudentInvites() {
  const [resendStudentInvitesMutation] = useMutation<ResendStudentInvites, ResendStudentInvitesVariables>(
    RESEND_STUDENT_INVITES
  );

  const resendStudentInvites = (params: SendAccountInvitesInput) => {
    resendStudentInvitesMutation({
      variables: {
        input: params,
      },
    });

    const { accountId, userIds } = params;

    trackEvent(trackingEvents.subscriptionResendUsersInviteRequested, {
      subscriptionId: accountId,
      users: userIds,
    });
  };

  return resendStudentInvites;
}
