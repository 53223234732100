// @flow
import { Box, Container, Flex, HideVisually, Skeleton, Stack } from '@getatomi/neon';

export default function LessonLoader() {
  return (
    <Container paddingInline={{ base: 'spacingNone', tablet: undefined }}>
      <HideVisually>Loading lesson.</HideVisually>
      <Skeleton animation="wave" height={28} width={185} />
      <Box
        aspectRatio="16/9"
        marginBottom="spacingLarge"
        marginTop="spacingLarge"
        marginInline="auto"
        maxHeight="58vh"
        overflow="hidden"
      >
        <Skeleton animation="wave" height="sizeFull" width="sizeFull" />
      </Box>
      <Container maxWidth="sizeContainerRoot">
        <Stack>
          <Skeleton animation="wave" height={36} width={531} />
          <Flex gap="spacingSmall1X">
            <Skeleton animation="wave" height={40} width={100} />
            <Skeleton animation="wave" height={40} width={100} />
            <Skeleton animation="wave" height={40} width={100} />
          </Flex>
        </Stack>
      </Container>
    </Container>
  );
}
